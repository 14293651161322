@import '@/assets/css/index.scss';

.monthsContainer {
    width: 100%;
}

.month {
    width: 22px;
    height: 18px;
    line-height: 14px;
    color: var(--color-nobel);
    border: 1px solid var(--color-nobel);
    border-radius: 3px;
    background-color: var(--color-white);
    text-align: center;
    padding-left: 1px;
    overflow: hidden;

    @include desktop {
        font-size: 12px;
    }

    &--isHighSeason {
        border: 1px solid var(--color-catalinaBlue);
        background-color: var(--color-catalinaBlue);
        color: var(--color-white);
    }

    &--isLowSeason {
        border: 1px solid var(--color-dustyGray);
        background-color: var(--color-dustyGray);
        color: var(--color-white);
    }

    &:not(&--isHighSeason):not(&--isLowSeason) {
        background-color: var(--color-wildSand);

        &::after {
            content: '.';
            transform: translate(-7px, 1px) rotate(-53deg);
            background-color: var(--color-nobel);
            height: 33px;
            width: 1px;
            color: transparent;
        }

        @include desktop {
            font-size: 12px;
        }
    }
}
