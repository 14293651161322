@import '@/assets/css/index.scss';

.headline {
    display: block;

    &--clamp {
        @include lineClamp(2);
    }

    &--center {
        text-align: center;
    }
}
