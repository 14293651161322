@import '@/assets/css/index.scss';

.filterBarOption {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 50px;
    padding: 0 2.5px;
    cursor: pointer;
}

.filterBarOptionLabel {
    padding-left: 3px;
    font-size: $font-size-small;
    overflow: hidden;
}

.sortText {
    font-size: $font-size-small;
    line-height: 14px;
}

.title {
    font-size: 10px;
}

.filterBarOptionSortMenu {
    opacity: 0;
    width: 100%;
    text-align: center;

    & > select {
        @include absolute-full;
    }
}

.activeFilterIconIndicator {
    border-radius: 5px;
    height: 5px;
    margin: -11px 0 0 -4px;
    width: 5px;
    z-index: 1;
    background-color: var(--color-monza);
}
