@import '@/assets/css/index.scss';

.tab {
    width: 60px;
    height: 65px;
    display: flex;
}

.label {
    height: 15px;
}

.highlightBar {
    height: 5px;
}
