@import '@/assets/css/index.scss';

.container {
    display: flex;
    align-items: center;
    min-width: 0;
    max-width: 100%;
}

.icon {
    align-self: flex-start;
    flex-shrink: 0;

    @include desktop {
        padding-top: 1px;
    }
}

.text {
    display: inline-block;
    align-self: center;

    &--truncation-ellipsis {
        @include ellipsis;
    }

    &--truncation-oneLine {
        @include lineClamp(1);
    }

    &--truncation-twoLines {
        @include lineClamp(2);
    }

    &--truncation-none {
        display: inline-block;
    }

    &--textAlignment-center {
        align-self: center;
    }

    &--textAlignment-start {
        align-self: flex-start;
    }
}

.infoIcon {
    display: inline;
    white-space: nowrap;
    position: relative;
    top: 2px;
    padding-left: 8px;
}
