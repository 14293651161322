@import '@/assets/css/index.scss';

.activityTile {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    color: var(--color-white);

    &:hover .imageZoom {
        transform: scale(1.1);
        transition: transform 0.5s ease;
        filter: brightness(1);
    }
}

.title {
    font-size: 20px;
    font-weight: bold;
    text-shadow: 0px 0px 9px var(--color-black-07);
    &--isFullWidth {
        font-size: 24px;
        font-weight: normal;
        text-shadow: none;
    }
}

.subtitle {
    font-size: 14px;
    text-shadow: 0px 0px 9px var(--color-black-07);
    &--isFullWidth {
        text-shadow: none;
    }
}

.titleWrapper {
    @include flex-gap-vertical(5px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px;
    &--isFullWidth {
        top: auto;
        bottom: 0;
    }
}

.imageZoom {
    transition: transform 0.5s ease;
    filter: brightness(0.8);
}
