@import '@/assets/css/index.scss';

.resetFilterContainer {
    height: 36px;
    font-size: 14px;
    background-color: var(--color-white);
}

.resetFilterText {
    margin-left: calc($content-padding / 2);
    color: var(--color-endeavour);
}
