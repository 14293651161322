@mixin ellipsis($wrap: nowrap) {
    white-space: $wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

@mixin lineClamp($lineCount) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lineCount;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    hyphens: auto;
    -webkit-hyphens: auto;
}
