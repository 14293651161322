@import '@/assets/css/index.scss';

.chip {
    padding: 0 10px;
    background-color: var(--color-white);
    border: var(--color-alto) 1px solid;
    border-radius: 3px;
    vertical-align: middle;
    cursor: pointer;
    max-width: 100%;

    &--normal {
        height: 32px;
        font-size: 14px;
    }

    &--S {
        height: 24px;
        font-size: 12px;
    }

    &--L {
        padding-top: 1px;
        height: 34px;
        font-size: $font-size-default;
    }

    &--XL {
        height: 38px;
        font-size: $font-size-medium;
        padding: 0 $content-padding;
    }

    &--XXL {
        height: 50px;
        font-size: $font-size-medium;
        padding: 0 $content-padding--medium;
    }

    &--shadow {
        box-shadow: rgba(0, 0, 0, 0.042) 1px 1px 2.6px;
    }

    &--rounded {
        border-radius: 30px;
        padding: 0 8px;
    }

    &--active {
        background-color: var(--color-foam);
        border: 1px solid var(--color-endeavour);
    }

    &--disabled:not(&--active) {
        background-color: var(--color-white);
        color: var(--color-alto);
        cursor: not-allowed;
    }

    &--loading {
        opacity: 0.6;
    }
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 1;
}

.text {
    width: 100%;
    white-space: nowrap;
    position: relative;
    @include area-expansion-after();
}

.icon {
    position: relative;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    &--closeIcon {
        width: 16px;
        height: 16px;
        margin-top: -2px;
        margin-bottom: -2px;
        border-radius: 50%;
        background-color: var(--color-endeavour-01);
    }

    &--largeCloseIcon {
        display: flex;
    }

    &--chevronIcon {
        display: flex;
    }

    &--largeChevronIcon {
        display: flex;
        rotate: 90deg;
    }
    @include area-expansion-after();
}
