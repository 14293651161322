@import '@/assets/css/index.scss';

.barChart {
    width: 100%;
}

.barChartData {
    height: 244px;
    background-color: var(--color-alabasterApprox);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4px $content-padding--small;
    margin-bottom: 9px;

    @include desktop {
        background-color: transparent;
        padding: 4px 0;
    }
}

.legend {
    width: 100%;
}

.legendLabel {
    font-size: 12px;
}

.legendColorPreview {
    width: 20px;
    height: 20px;
    border-radius: 3px;
}

.legendItem {
    @include flex-gap-horizontal;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: $content-padding;

    &:last-child {
        margin-bottom: 0;
    }
}

.stackBar {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    width: 20px;
    align-items: center;

    &--hasYOffset {
        gap: 16px;
    }
}

.stackBarItems {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.stackBarItemsLabel {
    font-size: 12px;

    &--active {
        font-weight: bold;
    }

    &--isWithinTravelPeriod {
        text-decoration: underline;
    }
}
