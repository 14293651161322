@import '@/assets/css/index.scss';

.topLivePricePrices {
    margin-top: -1px;
    gap: 4px;
    text-align: center;
    background-color: var(--color-white);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: $content-padding--small;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.topLivePrice {
    gap: 2px;
    padding: 4px;
    min-height: 22px;
}

.topLivePriceTitle {
    font-size: 11px;
    color: var(--color-mineShaft);
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: manual;
    -webkit-hyphens: manual;
    text-align: center;
}
