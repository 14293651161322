@import '@/assets/css/index.scss';

.filterGroupChipDesktop {
    position: relative;
    column-gap: 10px;
    padding: 0;
    background-size: cover;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin-right: -1px;
    margin-bottom: -1px;
    word-break: break-word;

    &--isLoading {
        opacity: 0.6;
        pointer-events: none;
    }

    &--isActive {
        z-index: 1;
    }

    &--isDisabled {
        color: var(--color-alto);
        cursor: not-allowed;
    }
}

.filterGroupChipLabel {
    hyphens: unset;
    -webkit-hyphens: unset;
}

.title {
    word-break: auto-phrase;
}
