@import '@/assets/css/index.scss';

.root {
    margin: 0;
    padding: 0 10px;
}

.root table {
    max-width: 100%;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
}

.months {
    display: block;
}

.header {
    width: 100%;
    top: 0;
}

.header.hasStickyHeader {
    position: sticky;
    z-index: 2; // to prevent overflow in overlays
}

.tabs {
    display: flex;
}

.caption_label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0;
}

.weekdays {
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
    margin: 0 10px;
    margin-top: 7px;
}

.weekdays.hasNoSpacing {
    margin-top: 0;
    padding: 0;
}

.weekday {
    display: flex;
    justify-content: center;
    flex: 1 1 0;
}

.day {
    height: 46px;
    padding: 0;
    text-align: center;
}

.day.hidden.outside {
    background-color: var(--color-white);
}

.day_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
}

.day_button:disabled {
    pointer-events: none;
}

.day_button:not([disabled]) {
    cursor: pointer;
}

.day_button[disabled]:not(.selected) {
    opacity: 0.25;
}

.day.range_middle button:disabled {
    color: var(--color-catalinaBlue);
    opacity: 1;

    @include desktop {
        color: var(--color-white);
        opacity: 1;
    }
}

.day.range_middle:hover button:disabled {
    @include desktop {
        color: var(--color-catalinaBlue);
    }
}

.day.range_end button:disabled {
    color: var(--color-white);
    opacity: 1;
}

.selected {
    color: var(--color-white);
    background-color: var(--color-endeavour);

    @include desktop {
        color: var(--color-white);
    }
}

.selected.isSingle {
    border-radius: 8px;

    @include desktop {
        border-radius: 3px;
        background-color: var(--color-catalinaBlue);
    }
}

.selected.outside {
    background-color: var(--color-white);

    @include desktop {
        background-color: var(--color-white);
    }
}

.today:not(.outside) {
    font-weight: bold;
}

.range_start {
    color: var(--color-white);
    background-color: var(--color-endeavour);
    border-radius: 8px 0 0 8px;

    @include desktop {
        background-color: var(--color-catalinaBlue);
        border-radius: 3px 0 0 3px;
    }
}

.range_middle {
    color: var(--color-mineShaft);
    background-color: var(--color-hawkesBlue);
    border-radius: 0;

    @include desktop {
        background-color: var(--color-catalinaBlue-02);
    }
}

.range_middle:hover {
    @include desktop {
        color: var(--color-catalinaBlue);
    }
}

.range_end {
    color: var(--color-white);
    background-color: var(--color-endeavour);
    border-radius: 0 8px 8px 0;

    @include desktop {
        background-color: var(--color-catalinaBlue);
        border-radius: 0 3px 3px 0;
    }
}

.footer {
    margin: 0.5em;
}
