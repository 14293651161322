@import '@/assets/css/index.scss';

.pagination {
    display: flex;
    justify-content: flex-end;
}

.paginationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-endeavour);
    font-size: 12px;
    cursor: pointer;

    &--hasPaddingRight {
        padding-right: $content-padding;
    }

    @include desktop {
        margin-top: $content-padding-desktop--small;
        font-size: $font-size-default-desktop;
    }
}

.loadingIcon {
    margin-right: $content-padding--small;
}
