@import '@/assets/css/index.scss';

.textWrapper {
    display: block;
    &--hasDisplayStyle {
        text-align: center;
        font-size: $font-size-small;
        color: var(--color-doveGray);
    }

    @include desktop {
        & ul {
            line-height: 24px;
        }
    }
}

.text {
    @include desktop {
        font-size: $font-size-body-default-desktop;
        line-height: 21px;
    }
}
