@import '@/assets/css/index.scss';

.travelFormInputOverlayDesktopWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    background-color: var(--color-white);
    border-radius: 3px;
    padding: 3px 8px;
    transition: all 0.1s ease-in-out;
    border: 1px solid var(--color-white);
    cursor: pointer;

    &--isActive,
    &:hover {
        border-color: var(--color-endeavour);
    }

    &--normal {
        justify-content: center;
        height: 45px;
    }

    &--large {
        width: 100%;
        height: 60px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--hasError {
        color: var(--color-persianRed);
        input {
            color: var(--color-persianRed);
        }
    }
}

.label {
    font-size: 12px;
    padding: 0;
}

.inputWrapper {
    width: 100%;
}

.inputOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.input {
    width: 100%;
    padding: 0;
    height: 100%;

    &::placeholder {
        color: var(--color-defaultDesktop);
    }
    &.input--isActive {
        &::placeholder {
            color: var(--color-dustyGray);
        }
    }

    &--normal,
    &--normal-dropdown {
        font-size: 14px;
        &::placeholder {
            color: var(--color-defaultDesktop);
        }
        &.input--isActive {
            &::placeholder {
                color: var(--color-dustyGray);
            }
        }
    }

    &--large {
        font-size: 16px;
    }

    &--large-dropdown {
        font-size: 16px;
        color: var(--color-defaultDesktop);
    }
}

.closeIcon {
    min-width: 13px;
    min-height: 13px;
    margin-left: 10px;
    border-radius: 50%;
    background-color: var(--color-nobel);
}

.travelFormInputOverlayDesktop {
    position: absolute;

    &--normal {
        top: 47px;
    }

    &--large {
        top: 68px;
    }

    &--isLastOverlay {
        top: 68px;
        right: -199px;
    }
}

.submitButtonContainer {
    width: 100%;
    padding: 10px 35px;
    position: sticky;
    bottom: 0;
    background-color: var(--color-white);
}

.overlayContent {
    position: relative;
    width: 100%;
    overflow: auto;
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: 1px 3px 10px var(--color-black-02);
}
