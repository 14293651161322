@import '@/assets/css/index.scss';

.icon {
    background-color: var(--color-white);

    @include desktop {
        margin: 5px;
    }
}

.container {
    aspect-ratio: 1/1;
    border-radius: 100%;

    width: 36px;
    right: 10px;

    @include desktop {
        height: 40px;
        width: 40px;
        right: 14px;
    }
}
