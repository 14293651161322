@import '@/assets/css/index.scss';

.button {
    box-shadow: 0 2px $content-padding 0 var(--color-black-026);
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);
    max-height: var(--maxHeight);
    min-height: var(--minHeight);
    width: var(--width);

    &--noShadow {
        box-shadow: none;
    }

    &--variant-primary {
        background: var(--color-lochmara);
        color: var(--color-white);
    }

    &--variant-secondary {
        background: var(--color-alabasterSolid);
        color: var(--color-doveGray);
        border: 1px solid var(--color-alto);
        box-shadow: $boxShadow;
    }

    &--size-big {
        font-size: 26px;
        padding: $content-padding--large 0;
    }

    &--size-medium {
        padding: 14px;
    }

    &--size-small {
        padding: 6px 0;
    }

    &--disabled {
        background-color: var(--color-alto);
        cursor: default;
    }

    &--hasLoadingStyle {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
