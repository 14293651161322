@import '@/assets/css/index.scss';
@import '../input-field/variables.scss';

$mobile-radio-padding-horizontal: 24px;

$input-error-color: var(--color-persianRed);
$input-success-color: var(--color-lima);
$input-disabled-color: var(--color-dustyGray);
$input-disabled-bg: var(--color-wildSand);

$mobile-input-font-size: 18px;

$mobile-input-text-color: var(--color-scorpion);
$mobile-input-border-color: var(--color-alto);
$mobile-input-border-color-hover: var(--color-scorpion);
$mobile-input-border-color-focused: var(--color-endeavour);
$mobile-input-border-color-disabled: var(--color-alto);

$mobile-input-height: 52px;
$mobile-input-highlight-color: var(--color-endeavour);
$mobile-shadow-text-color: var(--color-dustyGray);
$mobile-label-text: var(--color-doveGray);
$mobile-input-error-size: 14px;
$mobile-label-elevated-height: 9px;

$mobile-radio-padding-horizontal: 24px;

$radio-icon-color: var(--color-nobel);
$radio-icon-size: 22px;
$radio-icon-bullet-size: 10px;
$transition: 0.3s ease;

.radio {
    &.radio--has-label {
        padding-top: 15px;
    }

    &.radio--disabled {
        background-color: $input-disabled-bg;
    }

    .radio-label-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    .radio-after {
        flex: 0 1 auto;
        padding: 5px;
    }

    .radio-option {
        // Override label style
        position: relative;
        top: 0;
        left: 0;

        font-family: $mobile-input-font-family;
        // Disable webkit focus effect
        outline: none;
        display: flex;
        cursor: pointer;

        margin-right: $mobile-input-padding-horizontal;
        margin-left: $mobile-input-padding-horizontal;

        font-size: $mobile-input-font-size;
        color: $mobile-input-text-color;
        overflow: hidden;
        box-sizing: border-box;
        line-height: 20px;

        & > .radio-option-inner {
            position: relative;
            padding: 14px 10px 14px ($radio-icon-size + 8);
            flex: 1;
            min-width: 0;

            &::before {
                content: '';
                position: absolute;
                border-radius: 100%;
                border: 1px solid $radio-icon-color;
                width: $radio-icon-size;
                height: $radio-icon-size;
                box-sizing: border-box;
                left: 0;
                top: calc(50% - #{$radio-icon-size} / 2);
                transition: border-color $transition;
            }

            &::after {
                content: '';
                position: absolute;
                border-radius: 100%;
                width: $radio-icon-bullet-size;
                height: $radio-icon-bullet-size;
                background-color: $mobile-input-highlight-color;
                left: calc($radio-icon-size/2 - $radio-icon-bullet-size/2);
                opacity: 0;
                top: calc(50% - #{$radio-icon-bullet-size} / 2);
                transition: opacity $transition;
            }
        }

        input:checked + .radio-option-inner {
            &::before {
                border-color: $mobile-input-highlight-color;
            }

            &::after {
                opacity: 1;
            }
        }

        input:focus + .radio-option-inner {
            &::before {
                border-color: $mobile-input-highlight-color;
            }
        }

        input[type='radio'] {
            top: 0;
            left: 0;
            width: 0;
            cursor: inherit;
            height: 0;
            margin: 0;
            opacity: 0;
            padding: 0;
            z-index: 1;
            position: absolute;

            &:disabled {
                + .radio-option-inner {
                    &::before {
                        border-color: $input-disabled-color !important;
                    }

                    &::after {
                        background-color: $input-disabled-color !important;
                    }
                }
            }
        }
    }

    &.radio--horizontal {
        .radio-label-wrapper {
            flex-direction: row;
            padding: 8px 0;

            &.radio--has-label {
                padding-top: 18px;
            }

            .radio-option {
                flex: 1;
                margin: 0;
                padding-left: $mobile-input-padding-horizontal;
                padding-right: $mobile-input-padding-horizontal;

                .radio-option-inner {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    display: flex;
                    align-items: center;
                }

                + .radio-option {
                    border-left: 1px solid var(--color-alto);
                }
            }
        }
    }

    &.radio--vertical {
        .radio-option + .radio-option {
            border-top: 1px solid var(--color-alto);
        }
    }

    &.radio--is-invalid {
        .radio-option-inner {
            &::before {
                border-color: $input-error-color !important;
            }

            &::after {
                background-color: $input-error-color;
            }
        }
    }

    &.radio--is-valid {
        .radio-option-inner {
            &::before {
                border-color: $input-success-color !important;
            }

            &::after {
                background-color: $input-success-color;
            }
        }
    }
}
