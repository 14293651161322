@import '@/assets/css/index.scss';

.noResultsContainer {
    background-color: var(--color-white);
    padding: $content-padding;
    display: flex;
    flex-direction: column;
    @include desktop {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        padding: $content-padding--medium;
    }
}

.noResultsTileTitle {
    @include desktop {
        margin-bottom: $content-padding;
    }
}

.noResultsTileDescription {
    @include desktop {
        margin-bottom: $content-padding--medium;
    }
}

.noResultsFilterReset {
    color: var(--color-endeavour);
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}
