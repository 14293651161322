@import '@/assets/css/index.scss';

.layerSwitch {
    width: 36px;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.overlay {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 8px;
    padding-bottom: calc($safe-area-bottom + $content-padding);
}

.layerSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $content-padding--medium;
    background-color: var(--color-athensGray);
    padding: $content-padding--medium;
    border-radius: 10px;
    margin-bottom: $content-padding;
    z-index: 1;
}

.selectOptions {
    display: flex;
    gap: 54px;
}

.closeButton {
    padding: 16px;
    text-align: center;
    font-size: $font-size-large;
    background-color: var(--color-athensGray);
    border-radius: 10px;
    color: var(--color-endeavour);
}
