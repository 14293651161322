@import '@/assets/css/index.scss';

.mainRoomsWrapper {
    background-color: var(--color-white);
    @include flex-gap-vertical(16px);
    justify-content: flex-start;
    padding: $content-padding--medium $content-padding;
}

.roomDescriptionWraper {
    @include flex-gap-vertical($content-padding--small);
}

.roomConfigHeaderTitle {
    font-weight: bold;
}

.roomHint {
    @include flex-gap-horizontal($content-padding--small);
    color: var(--color-dustyGray);
    font-size: $font-size-extra-small;
    align-items: center;
}

.roomsWrapper {
    display: flex;
    justify-content: space-between;
}

.roomWrapper {
    @include flex-gap-vertical($content-padding);
    font-size: $font-size-extra-small;
    justify-content: flex-start;
}

.roomInfoWrapper {
    @include flex-gap-vertical($content-padding--small);
    justify-content: flex-start;
}

.changeRoomText {
    color: var(--color-endeavour);
    font-size: $font-size-extra-small;
    cursor: pointer;
}
