@import '@/assets/css/index.scss';

.checkbox {
    width: 22px;
    height: 22px;
    border: 1px solid var(--color-nobel);
    border-radius: 3px;
    margin-bottom: 1px; // For whatever reason the bottom part of the border is not part of the elements calculated height

    &--isSelected {
        background-color: var(--color-endeavour);
        border: none;
    }

    &--isDisabled {
        border-color: var(--color-alto);
    }

    &--isHovered:hover {
        border-color: var(--color-endeavour);
    }
}
