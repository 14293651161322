@import '@/assets/css/index.scss';

.inlineOverlay {
    height: inherit;
    display: grid;
    grid-template: 'container' 1fr;
    position: relative;
}

.content {
    grid-area: container;
    min-width: 0;
    position: relative;
}

.overlay {
    grid-area: container;
    min-width: 0;
    position: relative;

    visibility: hidden;

    &--show {
        visibility: visible;
    }
}
