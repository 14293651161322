@import '@/assets/css/index.scss';

$control-node-size: 27px;

@mixin track-styles {
    appearance: none;
    background: transparent;
    border: transparent;
}

@mixin thumb-styles {
    appearance: none;
    pointer-events: all;
    width: $control-node-size;
    height: $control-node-size;
    border-radius: unset;
    border: 0 none;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.rangeSlider {
    position: relative;
    display: flex;
    align-items: center;
    margin: calc($control-node-size / 2);
}

.inputWrapper {
    width: calc(100% + $control-node-size);
    margin: 0 calc($control-node-size / -2);
    position: absolute;
    height: $control-node-size;
}

.controlWrapper {
    width: 100%;
    position: absolute;
    height: $control-node-size;
}

.input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;

    &::-ms-track {
        @include track-styles;
    }

    &::-moz-range-track {
        @include track-styles;
    }

    &:focus::-webkit-slider-runnable-track {
        @include track-styles;
    }

    &::-ms-thumb {
        @include thumb-styles;
    }

    &::-moz-range-thumb {
        @include thumb-styles;
    }

    &::-webkit-slider-thumb {
        @include thumb-styles;
    }
}

.rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    border-radius: 3px;
    background: var(--color-dustyGray);
    @include area-expansion-after;
}

.innerRail {
    position: absolute;
    height: 100%;
    background: var(--color-endeavour);
}

.control {
    width: $control-node-size;
    height: $control-node-size;
    border-radius: 50%;
    position: absolute;
    background: var(--color-white);
    top: 50%;
    margin-left: calc($control-node-size / -2);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
    box-shadow: 0px 1px 2px 1px var(--color-black-02);
}
