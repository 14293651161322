@import '@/assets/css/index.scss';

.layerContent {
    @include flex-gap-vertical($content-padding--small);
}

.roomConfig {
    background-color: var(--color-white);
    padding: $content-padding--large $content-padding;
    @include flex-gap-vertical($content-padding);
}

.roomConfigHeaderTitle {
    font-weight: bold;
}

.roomConfigHeaderRemoveButton {
    color: var(--color-endeavour);
    cursor: pointer;
}

.addRoomButtonContainer {
    background-color: var(--color-white);
    padding: $content-padding--large $content-padding;
    height: inherit;
}

.addRoomButton {
    color: var(--color-endeavour);
    cursor: pointer;
}
