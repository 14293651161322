@import '@/assets/css/index.scss';

.navigationBar {
    @include flex-gap-horizontal();
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 10px;
    padding-left: 50px;
    background-color: var(--color-catalinaBlueWireframe);
    color: var(--color-white);
    -webkit-font-smoothing: auto;
    line-height: 18px;
    overflow: hidden;

    @media (max-width: 1450px) {
        padding-left: 28px;
    }
    @media (max-width: 1280px) {
        padding-left: 0px;
    }
}

.navigationItem {
    @include flex-gap-horizontal(10px);
    height: 34px;
    padding: 0 15px;

    &:hover {
        background-color: var(--color-stTropaz);
    }
}

.navigationItemIcon {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @media (max-width: 1380px) {
        display: none;
    }
}
