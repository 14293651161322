@import '@/assets/css/index.scss';

$quickfilter-desktop-container-width: 272px;

.mapQuickFilterDesktopContainer {
    height: 100%;
    min-width: $quickfilter-desktop-container-width;
    max-width: $quickfilter-desktop-container-width;
    margin-left: -5px;
    overflow-y: scroll;
}
