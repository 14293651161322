@import '@/assets/css/index.scss';

.spotScoreContainer {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: default;

    &--horizontal {
        @include flex-gap-horizontal(10px);
        flex: none;
    }

    &--horizontalReverse {
        @include flex-gap-horizontal(10px);
        flex-direction: row-reverse;
        justify-content: flex-end;
        flex: none;
    }

    &--vertical {
        flex-direction: column-reverse;
    }
}

.scoreBadge {
    border-bottom-right-radius: 0;

    &--small {
        width: 28px;
        height: 26px;
        font-size: 12px;
    }
}
