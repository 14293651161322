@import '@/assets/css/index.scss';
@import '../input-field/variables.scss';

$select-arrow-size: 14px;
$desktop-label-elevated-height: 12px;

.select {
    position: relative;

    select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: transparent;
        border: none;
        width: 100%;
        padding: ($desktop-input-padding-vertical + $desktop-label-elevated-height) $desktop-input-padding-horizontal
            $desktop-input-padding-vertical;
        outline: none;
        font-family: $desktop-input-font-family;
        color: var(--color-scorpion);
        font-size: $desktop-input-font-size;
        height: $desktop-input-height - 2px;
        line-height: 20px;
        z-index: 2;
        box-sizing: border-box;
        cursor: pointer;
        transition: color 0.15s ease-out;
        -moz-padding-start: $desktop-input-padding-horizontal - 3px; // Firefox adds to much padding on selects bug

        option[value=''] {
            display: none;
        }
    }

    &__arrow {
        max-width: $select-arrow-size;
        max-height: $select-arrow-size;
        margin-left: -14px; // Avoid pushing the select field so select is clicked on "click" on the icon
        margin-right: $desktop-input-padding-horizontal;
        pointer-events: none;
    }

    &--disabled {
        select {
            color: var(--color-alto);
        }
    }
}
