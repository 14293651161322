@import '@/assets/css/index.scss';

.form-control {
    user-select: none;
    margin-top: 8px;
    font-size: 14px;
    color: var(--color-dustyGray);

    &--info {
        color: var(--color-dustyGray);
    }

    &--error {
        color: var(--color-persianRed);
    }

    &--success {
        color: var(--color-lima);
    }
}
