@import '@/assets/css/index.scss';

.contentWrapper {
    background-color: var(--color-white);
    background-size: cover;
    column-gap: 10px;
    align-items: stretch;

    &--fullWidth {
        @include full-width();
    }

    &--hasPadding {
        padding: 10px 20px;
    }
}

.leftSideWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    flex: 1;
}

.headline {
    margin-bottom: 5px;
    white-space: normal;
    font-size: 18px;
}

.subHeadline {
    white-space: normal;
}

.mapButton {
    column-gap: 10px;
    color: var(--color-endeavour);
}
