$desktop-input-font-family: Verdana, 'Bitstream Vera Sans', 'DejaVu Sans', Tahoma, Geneva, Arial, sans-serif;
$desktop-input-padding-vertical: 6px;
$desktop-input-padding-horizontal: 16px;
// Reusable variable for the internal input field
$desktop-input-padding-inner: (8px + 12px + 1px) $desktop-input-padding-horizontal $desktop-input-padding-vertical;
$desktop-input-font-size: 14px;
$desktop-input-height: 47px;
$desktop-input-transition: ease 0.3s;

$mobile-input-font-family: Arial, Helvetica, 'Nimbus Sans L', 'Liberation Sans', FreeSans, sans-serif;
$mobile-input-padding-vertical: 10px;
$mobile-input-padding-horizontal: 16px;
// Reusable variable for the internal input field
$mobile-input-padding-inner: (8px + 12px + 3px) $mobile-input-padding-horizontal $mobile-input-padding-vertical;
$mobile-input-font-size: 18px;
$mobile-input-height: 58px;
$mobile-input-transition: ease 0.3s;
