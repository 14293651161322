@import '@/assets/css/index.scss';

.htmlTextWrapper {
    display: inline;

    p,
    ol,
    ul {
        margin-bottom: $content-padding;

        &:last-child {
            margin-bottom: 0;
            display: inline;
        }
    }

    ul {
        line-height: $content-padding--large;
        list-style-type: disc;
        padding-left: 0;
    }

    li {
        margin-left: calc($content-padding--large * 2);
    }

    ol {
        line-height: $content-padding--large;
        list-style-type: decimal;
        padding-left: 0;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }

    u {
        text-decoration: underline;
    }

    a {
        @include link-highlight();
    }
}
