@import '@/assets/css/index.scss';

.container {
    @include full-width();
    display: grid;
    grid-template-areas:
        'image1 image1 image1 image2 image2 image2'
        'image3 image3 image4 image4 image5 image5';
    grid-gap: 4px;
}
.image {
    position: relative;
    display: flex;
}
.image1 {
    grid-area: image1;
}
.image2 {
    grid-area: image2;
}
.image3 {
    grid-area: image3;
}
.image4 {
    grid-area: image4;
}
.image5 {
    grid-area: image5;
}
