@import '@/assets/css/index.scss';

.filterInput {
    padding: 8px $content-padding;
    background-color: var(--color-white);
    border-radius: 5px;
}

.filterLabel {
    display: block;
    font-size: 12px;
    color: var(--color-doveGray);

    @include desktop {
        margin-bottom: 3px;
    }
}
