@import '@/assets/css/index.scss';

.container {
    display: grid;
    grid-template-areas:
        'icon title arrow'
        'icon text  arrow';
    grid-template-columns: auto 1fr auto;
    row-gap: calc($content-padding / 2);
    column-gap: $content-padding--medium;
    padding: $content-padding--medium;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.title {
    grid-area: title;
    font-weight: bold;
}

.text {
    grid-area: text;
}

.icon {
    grid-area: icon;
}

.arrow {
    grid-area: arrow;
}
