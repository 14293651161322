@import '@/assets/css/index.scss';

.mapTooltip {
    align-self: flex-start;
    position: absolute;
    pointer-events: none;
    visibility: hidden;

    &--show {
        visibility: visible;
    }
}

.tileContainer {
    display: flex;
    pointer-events: auto;
}
