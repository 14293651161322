@import '@/assets/css/index.scss';

.wrapper {
    @include full-width();
    background-color: var(--color-white);
    display: flex;

    &--hasImage {
        color: var(--color-white);
        margin-bottom: 0;
        position: relative;
    }
}

.titleSignetWrapper {
    display: flex;
    justify-content: space-between;
    padding: $content-padding--large $content-padding--large $content-padding;
    width: 100%;
    z-index: 1;

    &--hasImage {
        height: 180px;
    }
}

.titleWrapper {
    display: flex;
}

.htmlTitle {
    font-size: 24px;
    line-height: 30px;

    p {
        line-height: 28px;
    }
}

.signetWrapper {
    width: 100px;
    height: 100px;
    align-self: flex-end;
}
