@import '@/assets/css/index.scss';

.optionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-bottom: $content-padding-desktop;
}

.title {
    margin-top: $content-padding-desktop;
    padding: 0 $content-padding-desktop;
    margin-bottom: 16px;
}

.title:first-child {
    margin-top: 0;
}

.optionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--disabled {
        color: var(--color-alto);
        cursor: not-allowed;
    }
}

.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: $content-padding-desktop--small;
    padding: 0 $content-padding-desktop;

    &--disabled {
        color: var(--color-alto);
        cursor: not-allowed;
    }
}
