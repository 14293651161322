@import '@/assets/css/index.scss';

.banner {
    display: flex;
    width: 100%;

    &--hasNegativeMarginBottom {
        margin-bottom: -25px;
    }
}

.overlay {
    height: inherit;
}

.image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.content {
    width: 100%;
    height: 100%;
    z-index: 1;
}
