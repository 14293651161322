@import '@/assets/css/index.scss';

.otherActivitiesContainer {
    margin-top: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: -10px;
    margin-right: -10px;
    align-items: flex-start;
}
