@import '@/assets/css/index.scss';

.selectBox {
    width: 100%;
    position: relative;
    background: var(--color-white);
    border-radius: 5px;
    padding: $content-padding;
    user-select: none;
}

.label {
    border-radius: 5px;
    height: 26px;
    cursor: pointer;
}
