@import '@/assets/css/index.scss';

.searchInputWithDropdown {
    position: relative;
    @include flex-gap-vertical($content-padding--small);
}

.dropdownWrapper {
    width: 100%;
    position: absolute;
    top: 45px;
}
