@import '@/assets/css/index.scss';

.spotScoreOverview {
    display: flex;
    padding-right: $content-padding;
    margin-right: -10px;
    flex-direction: column;

    &--default {
        display: flex;
    }

    &--tooltip {
        max-height: 100vh;
    }
}

.summary {
    flex-shrink: 1;
    margin-bottom: $content-padding--medium;
}

.heading {
    @include flex-gap-horizontal();
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    color: var(--color-catalinaBlue);
    padding-bottom: $content-padding;
}

.headingLogo {
    min-height: 30px;
}

.headingLabel {
    min-height: 30px;
    padding-bottom: 8px;
}

.title {
    font-size: $font-size-medium;
    font-weight: bold;

    &--default {
        display: block;
    }

    &--tooltip {
        font-size: $font-size-default;
        @include lineClamp(1);
    }
}

.subTitle {
    font-size: $font-size-medium;
    margin-bottom: $content-padding--medium;

    &--default {
        font-size: $font-size-medium;
    }

    &--tooltip {
        font-size: $font-size-default;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.chart {
    display: flex;
    flex-direction: column;
    margin-bottom: $content-padding;

    &:last-child {
        margin-bottom: 20px;
    }
}

.barLabel {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: $content-padding--small;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bar {
    background-color: var(--color-wildSand);
    width: 100%;
    height: $content-padding;
    border-radius: $content-padding--small;
}

.barFull {
    background-color: var(--color-endeavour);
    transition: width 0.8s;
    transition-timing-function: ease-in-out;
    height: $content-padding;
    border-radius: $content-padding--small;
}

.describeScoreText {
    font-size: 12px;
    margin-top: $content-padding--medium;

    &--default {
        margin-bottom: $content-padding;
    }

    &--tooltip {
        margin-bottom: 0;
    }
}
