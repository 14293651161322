@import '@/assets/css/index.scss';

.airport {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.airport:hover {
    background-color: var(--color-foam);
}

.selectedTitle {
    padding: 10px;
}

.allAirportsSelect {
    cursor: pointer;
    color: var(--color-endeavour);
    text-align: right;
    padding-right: 10px;
}

.airportWrapper {
    @include flex-gap-horizontal(10px);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 324px;
    height: 62px;
    padding: 0 20px;

    &--isDefault {
        height: 75px;
    }

    &--isHighlighted {
        background-color: var(--color-foam);
    }
}

.airportName {
    font-size: 16px;
    padding-right: 5px;
}

.airportNameName {
    font-size: 16px;
    padding-right: 5px;
}

.airportNameCode {
    color: var(--color-dustyGray);
    font-size: 14px;

    &::after {
        content: ')';
    }
    &::before {
        content: '(';
    }
}
.airportCheckbox {
    padding-right: 20px;
}
