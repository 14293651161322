@import '@/assets/css/index.scss';

.additionalImageContainer {
    background-color: var(--color-black);
    opacity: 0.3;
}

.additionalImageCount {
    color: var(--color-white);
}
