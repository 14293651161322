@import '@/assets/css/index.scss';

.travelFormDesktop {
    background-color: var(--color-mySin);
    border-radius: 3px;

    &--vertical {
        display: flex;
        flex-direction: column;
    }

    &--horizontal {
        width: 100%;
        z-index: 1;
        border-radius: 3px;
    }
}

.inputWrapper {
    padding: 5px;
    display: flex;
    gap: 5px;

    &--vertical {
        flex-direction: column;
    }

    &--horizontal {
        width: 100%;
        flex-direction: row;
        padding: 6px;
    }
}
