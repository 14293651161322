@import '@/assets/css/index.scss';

.dropDownWrapper {
    background-color: var(--color-white);
    @include flex-gap-vertical(36px);
    margin-top: $content-padding--large;
}

.select {
    width: auto;
    text-align: end;

    /* click area-expansion-after did not work on select */
    padding: $content-padding 65px;
    margin: -$content-padding -41px;
    z-index: 1;
    width: 187px;

    &--isInvalidAllocation {
        color: var(--color-persianRed);
    }
}

.hintText {
    font-size: $font-size-extra-small;

    &.isInvalidAllocation {
        color: var(--color-persianRed);
    }
}
