@import '@/assets/css/index.scss';
@import '@/assets/css/mixins/index.scss';

.textBannerDesktop {
    @include full-width();
    height: 250px;
    position: relative;
    overflow: hidden;
}

.contentWrapper {
    @include flex-gap-horizontal(50px);
    width: 100%;
    max-width: $max-width-desktop;
    height: 100%;
    padding: $content-padding;
}

.text {
    flex-grow: 1;
    color: var(--color-catalinaBlue);
    font-size: 30px;

    &--hasImage {
        color: var(--color-white);
    }
}

.imageWrapper {
    display: flex;
    align-items: center;
    min-width: 0;
    width: 100%;
}

.image {
    width: 100%;
    height: 250px;
}
