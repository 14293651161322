@import '@/assets/css/index.scss';
.title {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.tiles img {
    border-radius: 3px 3px 0 0;
}
