@import '@/assets/css/index.scss';

.mainWrapper {
    display: flex;
    flex-direction: column;
    padding: $content-padding--large;
    background-color: var(--color-white);
    row-gap: 13px;
    border: solid 1px var(--color-alto);
    border-radius: 5px;
}

.iconLink {
    width: 35px;
    min-width: 35px;
    height: 35px;
    border: solid 1px var(--color-endeavour);
    border-radius: 100%;
}

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: $content-padding-desktop--small;
    color: var(--color-endeavour);
}

.title {
    word-break: break-all;
}
