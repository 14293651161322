@import '@/assets/css/index.scss';

.gallerySwiperOverlayDesktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 2 * 20px 0 20px 0;
}

.content {
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.title {
    display: inline-block;
    margin-bottom: 33px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: var(--color-white);
}

.mainSlider {
    display: flex;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.previewSlider {
    @include full-width();
    max-height: 20%;
    user-select: none;
    min-height: 115px;
    margin: 20px 0;
}

.mainSlide {
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
}

.swiperControl {
    position: absolute;
    min-width: 50px;
    top: calc(50% - 50px);
    cursor: pointer;
    z-index: 1;
}

.swiperPrevIcon {
    left: 10px;
    transform: rotate(270deg);
}

.swiperNextIcon {
    right: 10px;
    transform: rotate(90deg);
}

.slideContent {
    display: flex;
    flex-direction: column;
}

.previewSlide {
    @include swiper-slide-override() {
        width: 145px;
        cursor: pointer;
    }
}

.previewSlideImage {
    display: flex;

    &--active {
        border: solid 3px var(--color-white);
    }
}
