@import '@/assets/css/index.scss';

.seaBanner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: $content-padding;
}

.textWrapper {
    z-index: 1;
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-bottom: $content-padding-desktop;
}

.title {
    color: var(--color-white);
    font-size: 24px;
    font-weight: bold;
}

.subtitle {
    color: var(--color-white);
    font-size: $font-size-medium;
    word-break: break-word;
    margin-top: $content-padding;
}

.ctaWrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 343px;
}
