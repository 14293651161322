@import '@/assets/css/index.scss';
@import './variables.scss';

@mixin colorize-state($color, $color-bg: false, $color-border: false) {
    @if $color-border {
        border-color: $color-border;
    }

    @if $color-bg {
        background: $color-bg;
    }

    label {
        color: $color;
    }
}

.input-field {
    border: 1px solid var(--color-nobel);
    border-radius: 5px;
    background: var(--color-white);
    color: var(--color-mineShaft);
    position: relative;
    min-height: $mobile-input-height;
    width: 100%;
    min-width: 0; // Flex box fix
    font-family: $mobile-input-font-family;
    font-size: $mobile-input-font-size;
    box-sizing: border-box;
    transition:
        border-color $mobile-input-transition,
        background-color $mobile-input-transition;
    display: flex;
    align-items: center;

    &:not(.input-field--disabled):hover {
        border-color: var(--color-scorpion);
    }

    .input-field__label {
        position: absolute;
        z-index: 0;
        font-size: $mobile-input-font-size;
        line-height: $mobile-input-font-size;
        left: $mobile-input-padding-horizontal;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.15s ease-out;
        color: var(--color-dustyGray);
        font-family: $mobile-input-font-family;
    }

    & > *:not(.input-field__label) {
        z-index: 1;
    }

    &.input-field--elevated,
    input:focus ~,
    select:focus ~,
    textarea:focus ~ {
        .input-field__label {
            font-size: 13px;
            line-height: 13px;
            top: 8px;
            transform: translateY(0);
            color: var(--color-doveGray);
        }
    }

    &.input-field--has-tooltip {
        .input-field__label {
            margin-right: 20px;
        }
    }

    &.input-field--focused {
        border-color: var(--color-endeavour);

        .input-field__label {
            color: var(--color-endeavour);
        }
    }
    // Needed to override focus colors
    input:focus ~,
    select:focus ~,
    textarea:focus ~ {
        .input-field__label {
            color: var(--color-endeavour);
        }
    }

    &.input-field--disabled {
        @include colorize-state(var(--color-dustyGray), var(--color-wildSand), false);
    }

    &.input-field--error {
        @include colorize-state(var(--color-persianRed), false, var(--color-persianRed));
    }

    &.input-field--success {
        @include colorize-state(var(--color-lima), false, var(--color-lima));
    }
}
