@import '@/assets/css/index.scss';

.travelFormActivity {
    padding: 3px 0;
    display: flex;
    flex-direction: column;
}

.activityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid var(--color-concrete);
    cursor: pointer;
    font-size: 16px;
}

.activityItemCount {
    width: 31px;
    height: 18px;
    background-color: var(--color-concrete);
    border-radius: 10px;
    color: var(--color-nobel);
    font-size: 12px;
}
