@import '@/assets/css/index.scss';
@import './variables.scss';

@mixin colorize-state($color, $color-bg: false, $color-border: false) {
    @if $color-border {
        border-color: $color-border;
    }

    @if $color-bg {
        background: $color-bg;
    }

    label {
        color: $color;
    }
}

.input-field {
    border: 1px solid var(--color-nobel);
    border-radius: 5px;
    background: var(--color-white);
    color: var(--color-scorpion);
    position: relative;
    min-height: $desktop-input-height;
    width: 100%;
    min-width: 0; // Flex box fix
    font-family: $desktop-input-font-family;
    font-size: $desktop-input-font-size;
    box-sizing: border-box;
    transition:
        border-color $desktop-input-transition,
        background-color $desktop-input-transition;
    display: flex;
    align-items: center;

    &:not(.input-field--disabled):hover {
        border-color: var(--color-scorpion);
    }

    .input-field__label {
        position: absolute;
        z-index: 0;
        font-size: $desktop-input-font-size;
        line-height: $desktop-input-font-size;
        left: $desktop-input-padding-horizontal;
        top: calc(($desktop-input-height - $desktop-input-font-size) / 2 - 1px);
        transition: all 0.15s ease-out;
        color: var(--color-doveGray);
        font-family: $desktop-input-font-family;
    }

    & > *:not(.input-field__label) {
        z-index: 1;
    }

    &.input-field--elevated,
    & > input:focus ~,
    select:focus ~,
    textarea:focus ~ {
        .input-field__label {
            font-size: 12px;
            line-height: 12px;
            top: $desktop-input-padding-vertical;
            color: var(--color-doveGray);
        }
    }

    &.input-field--focused {
        border-color: var(--color-endeavour);
    }

    &.input-field--disabled {
        @include colorize-state(var(--color-alto), var(--color-wildSand), false);
    }

    &.input-field--error {
        @include colorize-state(var(--color-persianRed), false, var(--color-persianRed));
    }

    &.input-field--success {
        @include colorize-state(var(--color-lima), false, var(--color-lima));
    }
}
