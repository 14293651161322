@import '@/assets/css/index.scss';

.tabBarWrapper {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding;
}

.tabBarTitle {
    margin: $content-padding 0;
    font-weight: bold;
}

.tabs {
    display: flex;
    column-gap: $content-padding;
    align-items: center;
    font-size: $font-size-small;
    margin-bottom: $content-padding;
    overflow-x: scroll;
}

.tab {
    background-color: var(--color-white);
    border: var(--color-alto) 1px solid;
    vertical-align: middle;
    cursor: pointer;
    padding: $content-padding;
    border-radius: 30px;
    white-space: nowrap;
    height: 34px;

    &--isSelected {
        background-color: var(--color-foam);
        border: var(--color-endeavour) 1px solid;
    }
}

.schoolProductTiles {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding;
}

.product {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding;
    background-color: var(--color-white);
    padding: $content-padding;
    border-radius: 5px;
}

.nameBadgeWrapper {
    column-gap: $content-padding;
    align-items: flex-start;
}

.strikePriceWrapper {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding--small;
}

.strikePriceText {
    width: 100%;
    text-align: end;
    color: var(--color-persianRed);
    text-decoration: line-through;
    font-size: $font-size-extra-small;
}

.priceWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: $content-padding--small;
    margin-top: -$content-padding--small;
}

.priceWrapper > span:not(.priceText) {
    font-size: $font-size-extra-small;
    padding-bottom: 3px;
}

.priceText {
    font-size: 25px;
}

.cancellationText {
    font-size: $font-size-extra-small;
    margin-top: -$content-padding--small;
    column-gap: 8px;
    align-items: flex-start;
}

.buttonMainWrapper {
    & > * {
        max-width: calc(50% - $content-padding--medium / 2);
    }

    &--hasNoCtaDetails {
        justify-content: flex-end;
    }
}

.ctaDetailsButton {
    border: 1px solid var(--color-silver);
    background-color: var(--color-white);
    color: var(--color-doveGray);
    box-shadow: 0 2px 3px var(--color-black-01);
}
