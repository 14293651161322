@import '@/assets/css/index.scss';

.counterWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &--start {
        @include flex-gap-horizontal($content-padding);
    }

    &--space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.upDownWrapper {
    @include flex-gap-horizontal($content-padding--medium);
}

.button {
    color: var(--color-white);
    background-color: var(--color-lochmara);
    border-radius: 8px;
    font-size: $font-size-extra-small;
    cursor: pointer;

    &--isMinCount {
        background-color: var(--color-white);
        border: 1px solid var(--color-dustyGray);
        cursor: default;
    }

    &--disableIncrease {
        background-color: var(--color-white);
        border: 1px solid var(--color-dustyGray);
        cursor: default;
    }
}

.label {
    color: var(--color-default);

    &--isLabelError {
        color: var(--color-persianRed);
    }
}

.count {
    text-align: center;
    color: var(--color-default);
    min-width: 18px;
    font-weight: bold;

    &--isValueError {
        color: var(--color-persianRed);
    }
}
