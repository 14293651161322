@import '@/assets/css/index.scss';

.loadingTile {
    height: 262px;
    @include flex-gap-horizontal();
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 4px;
    background-color: var(--color-white);
    border: 1px solid var(--color-alto);
}

.loadingTileContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $content-padding;
}

.loadingTileInformation {
    width: 100%;
    @include flex-gap-vertical();
}

.loadingTilePriceInformation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
