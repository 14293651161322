@import '@/assets/css/index.scss';

.title {
    margin-bottom: $content-padding-desktop--medium;
    font-size: $font-size-medium-desktop;
    font-weight: bold;
}

.climateDiagramWrapper {
    height: 100%;
    width: 100%;

    & > * {
        height: 100%;
    }
}

.swiperSlide {
    @include swiper-slide-override() {
        height: auto;
        display: flex;
    }
}

.swiperSlideSingle {
    @include swiper-slide-override() {
        width: 100% !important;
        height: auto;
        display: flex;
    }
}
