@import '@/assets/css/index.scss';

.pieChartV1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;

    @include desktop {
        border: 1px solid var(--color-alto);
        border-radius: 3px;
        padding: 20px;
    }
}

.svgIcon {
    transform: rotate(-90deg);
    overflow: visible;

    &--isNarrow {
        margin-top: 10px;
    }
}

.legend {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    list-style-type: none;

    @include desktop {
        padding-left: 0;
    }
}

.legendSqure {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    margin-right: 10px;
    flex-shrink: 0;

    @include desktop {
        width: 14px;
        height: 14px;
    }
}

.whiteCircel {
    position: absolute;
    left: 32px;
    top: 34px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-white);
    z-index: 1;

    &--isNarrow {
        top: 43px;
    }
}

.icon {
    @include desktop {
        margin-top: 2.5px;
    }
}
