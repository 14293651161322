@import '@/assets/css/index.scss';

@mixin defaultItemStyle {
    width: 30px;
    height: 30px;
    border: 1px solid var(--color-alto);
    border-radius: 8px;
    text-align: center;
}

.counter {
    @include flex-gap-horizontal($content-padding);
}

.count {
    @include defaultItemStyle;
    background-color: var(--color-white);
}

.button {
    @include defaultItemStyle;
    background-color: var(--color-wildSand);
    cursor: pointer;
}
