@import '@/assets/css/index.scss';
.mainLeft {
    max-width: 50%;
    min-width: 849px;
    padding: 10px 15px 15px 15px;
    @media only screen and (max-width: 1364px) {
        max-width: unset;
    }
    &--useLegacyMinWidth {
        min-width: 995px;
    }
    &--isMainContentHidden {
        min-width: unset;
    }
}
.mainContentAside {
    min-width: 254px;
    max-width: 254px;
    &--isMainContentHidden {
        margin-top: 5px;
    }
}
.mainContentSpotResult {
    position: relative;
    width: 100%;
    min-width: 550px;
    &--isMainContentHidden {
        display: none;
    }
}
.mainRight {
    display: flex;
    position: sticky;
    top: 0;
    min-width: 355px;
    width: 50%;
    &--isMainContentHidden {
        width: 100%;
    }
    @media only screen and (max-width: 1364px) {
        display: none;
        &--isMainContentHidden {
            display: flex;
            width: 100%;
        }
    }
}
.header {
    display: block;
    margin-bottom: 5px;
    &--isMainContentHidden {
        display: none;
    }
}
.leftRightContainer {
    display: grid;
    grid-template-columns: 80% 20%;
    width: 100%;
}
.toogleButton {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 20;
    background-color: var(--color-wildSand);
}
