@import '@/assets/css/index.scss';

.imageContainer {
    position: relative;
    width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.badge {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}
