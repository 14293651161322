@import '@/assets/css/index.scss';

.title {
    color: var(--color-white);
    margin-bottom: 6px;
}
.monthsContainer {
    width: 93.5px;
}

.month {
    width: 22px;
    height: 18px;
    line-height: 14px;
    border: 1px solid var(--color-white);
    border-radius: 3px;
    background-color: transparent;
    color: var(--color-white);
    overflow: hidden;

    &--isHighSeason {
        border: none;
        background-color: var(--color-white);
        color: var(--color-mineShaft);
    }

    &--isLowSeason {
        border: 1px solid var(--color-white);
        background-color: var(--color-white-06);
        color: var(--color-mineShaft);
    }

    &:not(&--isHighSeason):not(&--isLowSeason) {
        color: var(--color-white);
        border: 1px solid var(--color-white);
        background-color: var(--color-black-02);

        &::after {
            content: '.';
            transform: translate(-7px, 1px) rotate(-53deg);
            background-color: var(--color-white);
            height: 33px;
            width: 1px;
            color: transparent;
        }
    }
}
