@import '@/assets/css/index.scss';

.spotTile {
    display: grid;
    grid-template-columns: 150px minmax(0, 1fr);
    width: calc(100vw - 20px);
    overflow-wrap: anywhere;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    min-height: 300px;
    &--isVertical {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: unset;
        width: 240px;
        background-color: unset;
    }
}
.title {
    padding: $content-padding;
    background-color: var(--color-white);
}
.contentContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    position: relative;
    padding: $content-padding;
    flex-grow: 1;
    border-radius: 0 0 5px 0;
    &--isVertical {
        @include ellipsis;
        @include flex-gap-vertical(0);
        width: 100%;
        border: 1px solid #dcdcdc;
        border-radius: 0 0 5px 5px;
        padding-bottom: $content-padding;
    }
}
.keyFactsContainer {
    margin-top: 10px;
    min-width: 0;
}
.badges {
    margin-top: 12px;
    margin-bottom: $content-padding--medium;
}
.priceLevelContainerLayout {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}
.priceLevelContainer {
    align-items: flex-end;
    width: 100%;
}
.spotResultTitle {
    font-weight: bold;
    @include ellipsis();
}
.spotResultSubTitle {
    font-size: 12px;
    line-height: 16px;
    @include ellipsis();
}

.imageContainer {
    position: relative;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.horizontalImageContainer {
    width: 150px;
}
