@import '@/assets/css/index.scss';

.infoBoxV1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $content-padding;
    border: 1px solid var(--color-alto);
    background-color: var(--color-white);

    &--roundedBorder {
        border-radius: 5px;
    }

    &--large {
        padding: $content-padding--medium;
        padding-left: 25px;
    }
}

.infoBoxV1HtmlText {
    line-height: 18px;
    font-size: 14px;
    padding-right: $content-padding;

    & a {
        @include link-highlight;
    }
}

.infoBoxV1Icon {
    cursor: pointer;
    align-self: flex-start;
}
