@import '@/assets/css/index.scss';

.container {
    display: flex;
    justify-content: space-between;
    padding: $content-padding-desktop;
    padding-right: $content-padding;
    cursor: pointer;
    border-radius: 5px;
}

.innerContainer {
    @include flex-gap-vertical($content-padding);
}

.wrapper {
    @include flex-gap-horizontal($content-padding);
}

.title {
    max-width: 180px;
    font-weight: bold;
    font-size: $font-size-title-default-desktop;
}
