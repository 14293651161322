@import '@/assets/css/index.scss';

.otherActivityIconTextContainer {
    text-align: center;
    min-width: 82px; // min-width should be defined to avoid random gaps between OtherActivities
    max-width: 82px;
}

.otherActivityIconContainer {
    width: 58px;
    height: 58px;
    background-color: var(--color-athensGray);
    border-radius: 50%;
    margin-bottom: 10px;
}

.otherActivityText {
    font-size: 13px;
    text-align: center;
}
