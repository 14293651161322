@import '@/assets/css/index.scss';

.accommodationInfoContainerAccommodationTypeBadge {
    margin-left: -4px;
}

.accommodationTileRatingContainer {
    display: flex;
    min-width: 0;
    align-items: center;

    &--hasRating {
        align-items: unset;
    }
}

.accommodationTileRating {
    min-width: 25px;
    height: 25px;
    background-color: var(--color-catalinaBlue);
    margin-right: 7px;
    border-bottom-right-radius: 0;
}

.accommodationTileRatingCount {
    height: 14px;
    font-size: 10px;
    color: var(--color-doveGray);

    @include desktop {
        font-size: 12px;
    }
}
