@import '@/assets/css/index.scss';

.htmlTooltip {
    top: 20px;
    right: 15px;
}

.spsFormFieldContainer {
    iframe {
        min-height: 60px;
    }
}
