@import '@/assets/css/index.scss';

.mapWrapper {
    overflow: hidden;

    iframe + div {
        border-color: transparent !important;
    }

    @include google-maps-style-override() {
        font-family: inherit;
    }
}

.googleMap {
    flex-grow: 1;
    height: 100%;
    min-height: 200px;
}
