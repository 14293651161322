@import '@/assets/css/index.scss';

.badgeWrapper {
    margin: -4px;
}

.mapAccommodationTileDetailsSavings {
    margin-bottom: -4px;
}

.mapAccommodationTileRatingContainer {
    display: flex;
    min-width: 0;
    align-items: center;
    margin-bottom: 20px;

    &--hasRating {
        align-items: unset;
    }
}

.nameBadgeStarsWrapper {
    gap: 7px;
    margin-bottom: -2px;
}

.accommodationTileRating {
    min-width: 32px;
    height: 32px;
    background-color: var(--color-catalinaBlue);
    margin-right: 7px;
    border-bottom-right-radius: 0;
}

.accommodationTileRatingCount {
    height: 14px;
    color: var(--color-doveGray);

    @include desktop {
        font-size: 12px;
    }
}
