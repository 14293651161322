@import '@/assets/css/index.scss';

.button {
    @include flex-gap-horizontal($content-padding-desktop--small);
    height: 43px;
    width: 100%;
    background-color: var(--color-white);
    border: solid 1px var(--color-lochmara);
    border-radius: 3px;
    color: var(--color-endeavour);
    cursor: pointer;
    font-size: $font-size-body-default-desktop;
}
