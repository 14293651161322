@import '@/assets/css/index.scss';

.header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 15px;
    z-index: 2;
    background-color: var(--color-white);

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        height: 10px;
        width: 100%;
        background-color: var(--color-white);
    }
}

.productName {
    font-weight: bold;
}

.text {
    font-size: 14px;
    margin-bottom: 5px;
}

.schoolInfo {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding--small;
}

.datePicker {
    min-height: 80vh;
}

.footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background-color: var(--color-white);

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        height: 10px;
        width: 100%;
        background-color: var(--color-white);
        z-index: -1;
    }
}

.counterCtaWrapper {
    display: flex;
    column-gap: 20px;
}

.counter {
    flex-direction: column;
    row-gap: 6px;
    font-size: 14px;
}

.button {
    padding: 6px 5px;

    @media only screen and (max-width: 375px) {
        font-size: 14px !important;
        max-height: fit-content !important;
    }
}

.selectedDate {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.cancellationText {
    padding: 13px 0 3px;
    font-size: 14px;
    column-gap: 8px;
    align-items: flex-start;
}
