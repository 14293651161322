@import '@/assets/css/index';

// Expand click area of checkstrap delete icons
.areaExpandCheckstrapDeleteIcon {
    & > div:has(> svg) {
        position: relative;
        @include area-expansion-after(5px);
    }

    &--tooltipPlaceholder {
        & > div:has(> svg) {
            margin-right: 35px;
        }
    }
}

.formFieldDesktop {
    border-color: var(--color-alto) !important;
    > div {
        border-color: var(--color-alto);
        > input {
            color: var(--color-mineShaft) !important;
        }
    }
    > select {
        color: var(--color-mineShaft) !important;
    }

    &--invalid {
        border-color: var(--color-persianRed) !important;

        > div {
            border-color: var(--color-persianRed);
        }
    }

    div:is([class*='--elevated'][class*='--focused']) > label {
        color: var(--color-endeavour) !important;
    }
}
