@import '@/assets/css/index.scss';

.mapTypeSelectOption {
    width: 67px;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $content-padding;
}

.mapTypeSelectOptionImage {
    border-radius: 50%;
    overflow: hidden;
    border: transparent solid 1px;

    &--active {
        border: var(--color-endeavour) solid 1px;
    }
}

.mapTypeSelectTitle {
    font-size: 14px;

    &--active {
        color: var(--color-endeavour);
    }
}
