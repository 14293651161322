@import '@/assets/css/index.scss';

.seaBannerDesktop {
    padding-top: 148px;
    display: flex;
    flex-direction: column;
}

.title {
    text-align: center;
    color: var(--color-white);
    font-size: 30px;
    font-weight: bold;
    margin-bottom: $content-padding-desktop--medium;
}

.subtitle {
    text-align: center;
    color: var(--color-white);
    font-size: $font-size-medium-desktop;
}

.ctaWrapperDesktop {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 343px;
    margin-top: calc($content-padding-desktop--medium * 2);
}
