@import '@/assets/css/index.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title {
    font-size: 16px;
    font-weight: bold;

    @include desktop {
        font-size: 18px;
        margin-bottom: -10px;
    }
}

.htmlText {
    font-size: 14px;

    @include desktop {
        font-size: 16px;
    }
}
