@import '@/assets/css/index.scss';

.mapButton {
    font-weight: bold;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 20px;

    @include desktop {
        font-weight: normal;
        font-size: 18px;
        border-radius: 40px;
        padding: 15px 25px;
        flex-direction: row-reverse;

        @media only screen and (max-width: 1364px) {
            display: flex;
            z-index: 1;
        }

        @media only screen and (min-width: 1365px) {
            display: none;
        }
    }
}
