@import '@/assets/css/index.scss';
@import '../input-field/input-field-desktop.module.scss';

$cs-desktop-checkbox-color-disabled: var(--color-alto);
$cs-desktop-checkbox-color-error: var(--color-persianRed);
$cs-desktop-checkbox-border-color: var(--color-nobel);
$cs-desktop-checkbox-border-color-checked: var(--color-endeavour);
$cs-desktop-checkbox-border-color-hover: var(--color-scorpion);
$cs-desktop-checkbox-border-color-focused: var(--color-endeavour);
$cs-desktop-checkbox-size: 1.4em;

.checkbox {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: $desktop-input-font-family;
    font-size: $desktop-input-font-size;
    outline: none;

    & * {
        box-sizing: border-box;
    }

    input[type='checkbox'] {
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--color-white);
        /* Not removed via appearance */
        margin: 0;
        cursor: inherit;
        font: inherit;

        // New Styles
        flex: 0 0 auto;
        height: $cs-desktop-checkbox-size;
        width: $cs-desktop-checkbox-size;
        color: currentColor;
        border: 0.1em solid $cs-desktop-checkbox-border-color;
        border-radius: 0.15em;
        margin-right: 0.5em;
        transition:
            border-color 0.2s ease,
            color 0.15s ease,
            0.1s background-color ease-in-out;
        display: grid;
        place-content: center;

        // Checkmark
        &::before {
            content: '';
            width: 0.8em;
            height: 0.8em;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            transition: 0.1s transform ease-in-out;
            box-shadow: inset 1em 1em var(--color-white);
            background-color: var(--color-white);
        }

        &:focus {
            outline: none;
        }

        &:checked {
            background-color: $cs-desktop-checkbox-border-color-checked;
            &::before {
                transform: scale(1);
            }
        }
    }

    &:hover {
        > input {
            border-color: $cs-desktop-checkbox-border-color-hover;
        }
    }

    &#{&}--error {
        > input[type='checkbox'] {
            border-color: $cs-desktop-checkbox-color-error;
        }
    }

    &#{&}--disabled {
        cursor: not-allowed;

        > input[type='checkbox'] {
            background-color: $cs-desktop-checkbox-color-disabled;

            &:hover {
                border-color: $cs-desktop-checkbox-color-disabled;
            }

            &:checked {
                border-color: $cs-desktop-checkbox-color-disabled;
                color: $cs-desktop-checkbox-color-disabled;
                box-shadow: 0 0 0 1px $cs-desktop-checkbox-color-disabled inset;
            }
        }

        span {
            color: $cs-desktop-checkbox-color-disabled;
        }
    }
}
