@import '@/assets/css/index.scss';
.quickFilterOverlayWrapper {
    position: relative;
}

.quickFilterOverlayChipContainer {
    margin-bottom: 16px;
}

.title {
    font-size: $font-size-large;
    margin-bottom: 16px;
}

.button {
    bottom: 0;
    left: 0;
    width: 100%;
    position: sticky;
    background-color: var(--color-white);
    padding-bottom: 15px;
}
