@import '@/assets/css/index.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--color-white);
}

.sidebarWrapper {
    display: flex;
    z-index: 40043;
    position: fixed;
    bottom: 60px;
    height: calc(100vh - 200px);
    width: 100%;
    max-width: 376px;

    overflow: auto;
    overscroll-behavior: contain;
    background-color: var(--color-white);

    border-radius: 10px;
    box-shadow:
        rgba(0, 0, 0, 0.26) 0 14px 28px,
        rgba(56, 42, 42, 0.26) 0 10px 10px;

    right: 10px;

    @include desktop {
        top: 140px;
    }
}

.toggleWrapper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    z-index: var(--z-index-hiddenMenu);
}

.toggleButton {
    width: 37px;
    height: 37px;
    background-color: var(--color-mineShaft-03);
    border-radius: 50%;
    border: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: var(--color-mineShaft-07);
    }
}

.sidebar {
    background-color: var(--color-alabasterApprox);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    z-index: var(--z-index-hiddenMenu);
}

.inputWrapper {
    background-color: var(--color-alabasterApprox);
    padding: 10px;
    display: flex;
    z-index: 1;
    position: sticky;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.042) 1px 1px 2.6px;
}

.inputContainer {
    width: 100%;
    padding: 10px 20px;
    border-radius: 3px;
    background-color: var(--color-white);
    border: 1px solid var(--color-doveGray);
}

.input {
    width: 100%;
    color: var(--color-default);

    &::placeholder {
        color: var(--color-dustyGray);
    }
}

.collapsible {
    width: 100%;
    padding: 5px 10px;
}

.active {
    background-color: var(--color-lochmara);
    color: var(--color-white);

    &:hover {
        color: var(--color-white);
        background-color: var(--color-lochmara);
    }
}

.inActive {
    &:hover {
        color: var(--color-lochmara);
    }
}

.collapsableTitle {
    display: flex;
    gap: 5px;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    user-select: none;

    &:hover {
        color: var(--color-azureRadiance);
    }
}

.collapsableItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 20px;
}
