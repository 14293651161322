@import '@/assets/css/index.scss';

.priceLevel {
    display: inline-block;
    position: relative;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;

    @include desktop {
        font-size: $font-size-title-default-desktop;
        line-height: 16px;
    }
}
