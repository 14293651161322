@import '@/assets/css/index.scss';

.filterOverlayContent {
    margin-top: 16px;
    padding: 0 10px calc(50px + 40px) 10px;
}

.filterOverlaySubmitButtonContainer {
    width: 100%;
    position: fixed;
    bottom: $safe-area-bottom;
    padding: 0 10px 20px 10px;
    background-color: var(--color-white);

    :global(body.fixed) & {
        bottom: 0;
    }
}
