@import '@/assets/css/index.scss';

.filterBar {
    @include full-width();
    display: flex;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-alto);
}

.loadingBar {
    position: absolute;
    left: 0;
    right: 0;
}
