@import '@/assets/css/index.scss';

.tilesGrid {
    display: grid;
    gap: 10px;

    &--layout1 {
        grid-template-areas: 'tile1';
    }

    &--layout2 {
        grid-template-areas:
            'tile1'
            'tile2';
    }

    &--layout3 {
        grid-template-areas:
            'tile1 tile2'
            'tile1 tile3';
    }
}

.tilesSwiper {
    height: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 10px;
}
