@import '@/assets/css/index';

.contentContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    min-height: 300px;
    overflow: hidden;
    border-radius: 5px;
}

.previewContainer {
    position: relative;
    width: 550px;
    cursor: pointer;

    @media (max-width: 1366px) {
        width: 525px;
    }

    @media (max-width: 1280px) {
        width: 455px;
    }

    @media (max-width: 1152px) {
        width: 325px;
    }

    @media (max-width: 1024px) {
        width: 300px;
    }
}

.updatedText {
    font-size: 12px;
    margin-top: 45px;
}
