@import '@/assets/css/index.scss';

.select {
    width: 187px;
    position: relative;
}

.selectedValue {
    color: var(--color-mineShaft);
    border: 1px solid var(--color-mineShaft);
    padding: $content-padding;
    border-radius: 5px;
    cursor: pointer;

    &--isOpen {
        border: 1px solid var(--color-lochmara);
    }
}

.iconWrapper {
    margin-right: $content-padding--small;
}

.dropdownWrapperDropdownSelect {
    width: 100%;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    z-index: 1;
    background-color: var(--color-white);
}

.item {
    padding: $content-padding;
    cursor: pointer;

    &:hover {
        background-color: var(--color-wildSand);
    }
}
