@import '@/assets/css/index.scss';

.cta {
    padding: $content-padding $content-padding * 2;
    @include full-width();
    background-color: var(--color-white);
}

.ctaText {
    margin-bottom: $content-padding;
    line-height: $content-padding--large;
}
