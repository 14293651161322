@import '@/assets/css/mixins/index.scss';

.livePrices {
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    background-color: var(--color-white);
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 1px 2px var(--color-black-01);
    z-index: 1;
}

.livePriceTile {
    flex-direction: column;
    background-color: var(--color-white);
    text-align: center;
    flex-grow: 1;
    padding: 5px;
    height: 100%;
}

.text {
    align-items: center;
    padding-top: 5px;
    line-height: 12px;
}
