@import '@/assets/css/index.scss';

.grid {
    display: grid;
    grid-gap: 20px;
    grid: auto / repeat(6, minmax(auto, 1fr));
}

// by default every item spans 2 of the 6 columns
.gridItem {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    grid-column: span 2;

    // case first two overall items: span 3 columns
    &--row--1,
    &--row-0 {
        grid-column: span 3;
    }

    // case second item in row is last item or first item in row is second to last item: span 3 columns
    &--row-2:nth-last-of-type(1),
    &--row-1:nth-last-of-type(2) {
        grid-column: span 3;
    }

    // case first item in row or first item overall is last item: span 6 columns
    &--row-1:nth-last-of-type(1),
    &--row--1:nth-last-child(1) {
        grid-column: span 6;
    }

    // Provide class to satisfy bem
    &--row-3 {
        display: flex;
    }
}
