@import '@/assets/css/index.scss';

.tooltip {
    position: absolute;
    right: 16px;
    top: 19px;
}

.helpIcon {
    height: 20px;
    width: 20px;
    color: var(--color-nobel);
}
