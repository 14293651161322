@import '@/assets/css/index.scss';

.video {
    display: flex;
    max-width: 100%;
    max-height: 100%;

    &--responsive {
        @include desktop {
            position: absolute;
        }
        height: 100%;
    }
}

.video:fullscreen {
    object-fit: contain !important;
}
