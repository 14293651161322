@import '@/assets/css/index.scss';

.radioButton {
    display: inline-block;
}

.circle {
    border: 1px solid var(--color-nobel);
    border-radius: 50%;
    height: 22px;
    width: 22px;
    margin-bottom: 1px; // For whatever reason the bottom part of the border is not part of the elements calculated height

    &--isSelected {
        border-color: var(--color-endeavour);
    }

    &--isDisabled {
        border-color: var(--color-alto);
    }

    &--isHovered:hover {
        border-color: var(--color-endeavour);
    }
}

.dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;

    &--isSelected {
        background-color: var(--color-endeavour);
    }

    &--isDisabled {
        background-color: var(--color-alto);
    }
}
