@import '@/assets/css/index.scss';

.tabView {
    width: 100%;
    background-color: var(--color-white);
}

.tabBar {
    display: flex;
    flex: 1;

    &--hasMoreThanFourTabs {
        overflow-x: auto;
    }
}

.tabBarItem {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;
    cursor: pointer;

    &--active {
        font-weight: bold;
    }

    &--hasMoreThanFourTabs {
        min-width: 100px;
    }
}

.tabBarItemTitle {
    min-height: 30px;
}

.tabBarItemIndicator {
    height: 2px;
    background-color: var(--color-alto);

    &--active {
        border-bottom: 2px solid var(--color-default);
        animation: 0.2s ease-in-out;
    }
}
