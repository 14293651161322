@import '@/assets/css/index.scss';

.button {
    width: 34px;
    height: 34px;
    background-color: var(--color-white);
    border: 1px solid var(--color-nobel);
    border-radius: 50%;
    z-index: 1;
}

.icon {
    margin-top: -2px;
}
