@import '@/assets/css/index.scss';

.contactBarV1 {
    display: flex;
    justify-content: space-evenly;
    padding: $content-padding 0;
    @include full-width();
    background-color: var(--color-white);

    &--hasContentBox {
        padding: 0;
    }
}

.iconLink {
    width: 35px;
    height: 35px;
    border: solid 1px var(--color-endeavour);
    border-radius: 100%;
}
