@import '@/assets/css/index.scss';

.roomAllocationOverlayContent {
    padding: $content-padding--large;
}
.roomAllocationOverlayBody {
    @include flex-gap-vertical($content-padding--large);
    &--isExtended {
        padding-bottom: $content-padding--large;
    }
}

.roomAllocationErrorTextcontainer {
    margin-bottom: $content-padding--large;
}

.selectAgeAllocation {
    border: 1px solid var(--color-silver);
    border-radius: 3px;
    padding-left: $content-padding--medium;
}

.errorText {
    margin-top: $content-padding--large;
    margin-bottom: $content-padding;
    color: var(--color-persianRed);
}
