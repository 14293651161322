@import '@/assets/css/index.scss';

.imageWrapper {
    margin: 0 auto;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    transition:
        backdrop-filter 600ms ease-out,
        background-image 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    color: transparent; // hide alt tag when image is loading

    &--showPlaceholder {
        &:before {
            @include absolute-full; // use inset: 0 when iOS <14.5 support is dropped
            backdrop-filter: blur(10px);
            content: '';
        }

        &:after {
            @include absolute-full; // use inset: 0 when iOS <14.5 support is dropped
            animation: 1s ease-in-out infinite 0.5s normal none running shimmer;
            will-change: transform;

            background-size: 200% 100%;
            background-position-x: 280%;
            background: linear-gradient(
                    120deg,
                    var(--color-alto-00) 30%,
                    var(--color-alto-01) 50%,
                    var(--color-alto-00) 70%
                )
                transparent;
            content: '';
        }
    }

    &:before {
        content: '';
    }

    &:after {
        content: '';
    }

    // show a light placeholder color to indicate images
    &--cover:not(&--inline) {
        background-color: var(--color-alabasterSolid-01);
    }

    &--cover {
        background-size: cover;

        &:before {
            object-fit: cover;
        }
    }

    &--contain {
        background-size: contain;

        &:before {
            object-fit: contain;
        }
    }
}

.image {
    display: flex;
    opacity: 1;
    width: 100%;
    height: 100%;

    &--cover {
        object-fit: cover;
    }

    &--contain {
        object-fit: contain;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(150%);
    }
}
