@import '@/assets/css/index.scss';

.hint {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: $content-padding;
    column-gap: $content-padding;
    word-wrap: break-word;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding--small;
    width: 100%;
}

.hintText {
    line-height: 20px;
}

.icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.title {
    font-weight: bold;
}
