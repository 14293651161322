@import '@/assets/css/index.scss';

.overlayContent {
    position: relative;
    border-radius: 10px;
    @include flex-gap-vertical($content-padding--small);
    height: 100%;
    margin-bottom: $content-padding;
}

@mixin defaultBorder {
    border: 1px solid var(--color-alto);
    border-radius: 8px;
}

.durationOverlay {
    padding: $content-padding--small 0;
    background-color: var(--color-wildSand);
    @include flex-gap-vertical($content-padding--small);
}

.contentContainer {
    background-color: var(--color-white);
    padding: $content-padding--large $content-padding--large;
}

.presetChip {
    @include defaultBorder;
    text-align: center;
    padding: 12px;
    cursor: pointer;

    &--isActive {
        background-color: var(--color-foam);
        border-color: var(--color-endeavour);
    }
}

.borderedContainer {
    @include defaultBorder;
    padding: $content-padding--small;

    &--isActive {
        border-color: var(--color-lochmara);
        background-color: var(--color-foam);
    }
}

.dayCounterWrapper {
    @include flex-gap-horizontal($content-padding--medium);
    align-items: center;
}

.rangeSliderContainer {
    width: 100%;
    padding: 22px $content-padding--large;
    @include flex-gap-vertical($content-padding--medium);
}

.rangeSliderWrapper {
    margin-bottom: 25px;
}

.rangeNumber {
    width: 56px;
    text-align: right;
}

.exactDays {
    @include flex-gap-vertical($content-padding);
    padding: $content-padding--medium;
    cursor: pointer;
}

.exactDaysLabel {
    font-size: $font-size-small;
    color: var(--color-nobel);
}
