@import '@/assets/css/index.scss';

.overlayContent {
    width: 100%;

    &--isLoading:after {
        @include absolute-full;
        content: '';
        background-color: var(--color-white-05);
    }

    &:first-child {
        padding: 0;
    }

    &:last-child {
        padding: 0;
    }
}

.noResultWrapper {
    background-color: var(--color-white);
    padding: 20px 20px 0 20px;
}

.noResultText {
    font-weight: bold;
    font-size: 16px;
}

.noResultIcon {
    margin-left: 30px;
    opacity: 0.5;
}

.loadingSpinner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}
