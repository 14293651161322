@import '@/assets/css/index.scss';

.seaBanner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
}

.contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.textWrapper {
    font-size: 30px;
    align-self: flex-start;
    z-index: 1;
    row-gap: $content-padding--medium;
    margin-bottom: 55px;
}

.fixedFallbackHeight {
    height: 228px;
}

.text {
    font-size: $font-size-extra-small;
}

.signet {
    margin-bottom: 25px;
}

.divider {
    position: relative;
    margin-top: 33px;
    left: 50%;
    margin-left: min(calc(-1024px / 2), -50vw);
    width: max(1024px, 100vw);
    border-top: 1px solid var(--color-alto);
}

.mapButton {
    margin-top: 73px;
}
