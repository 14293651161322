@import '@/assets/css/index.scss';

.mainWrapper {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding--medium;
    padding: $content-padding;
}

.buttonPriceWrapper {
    display: flex;
    align-items: center;
    column-gap: $content-padding--medium;
}

.buttonWrapper {
    width: 168px;
}

.priceMainWrapper {
    flex-direction: column;
    align-items: flex-end;
    row-gap: $content-padding--small;
    flex: 1;
}

.strikePriceText {
    text-decoration: line-through;
    color: var(--color-persianRed);
    font-size: $font-size-extra-small;
}

.priceWrapper {
    display: flex;
    align-items: flex-end;
    column-gap: $content-padding--small;
}

.from {
    font-size: $font-size-extra-small;
}

.courseName {
    font-weight: bold;
}

.priceText {
    font-size: 25px;
    height: 25px;
    margin-top: -2px;
}

.courseNameWrapper {
    display: flex;
    flex-direction: column;
    row-gap: $content-padding--small;
}

.htmlText > ul > li {
    margin-left: 16px;
    line-height: 20px;
}
