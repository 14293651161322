@import '@/assets/css/index.scss';

.infoContainer {
    display: flex;
    align-items: center;
    gap: $content-padding;
    border-radius: 3px;

    &--fullWidth {
        @include full-width($content-padding);
    }

    &--marginTop {
        margin-top: $content-padding--medium;
    }
}

.iconWrapper {
    height: 100%;
}
