@import '@/assets/css/index.scss';

.activityTile {
    position: relative;
    border-radius: 5px;
    color: var(--color-white);
    overflow: hidden;
    display: flex;
    flex-shrink: 0;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 40%;
        background-image: linear-gradient(0deg, var(--color-mineShaft-085) 0%, var(--color-mineShaft-00) 100%);
        background-position: center;
        background-size: cover;
        pointer-events: none;
    }
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.subtitle {
    font-size: 14px;
}

.titleWrapper {
    z-index: 1;
    pointer-events: none;
    @include flex-gap-vertical(5px);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px;
    text-shadow: 0 0 9px var(--color-black-07);
}
