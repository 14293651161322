@import '@/assets/css/index.scss';

@keyframes background-shimmer {
    to {
        background-position-x: -20%;
    }
}

.shimmerBox {
    border-radius: 3px;

    background: linear-gradient(120deg, var(--color-alto-00) 30%, var(--color-alto-05) 50%, var(--color-alto-00) 70%)
        var(--color-athensGray);

    background-size: 300% 100%;
    background-position-x: 280%;
    animation: background-shimmer 1.5s linear 0.5s infinite normal none running;
    will-change: transform;
}
