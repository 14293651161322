@import '@/assets/css/index.scss';

.contentWrapper {
    position: relative;
    width: 414px;
    &--isLoading:after {
        @include absolute-full;
        content: '';
        background-color: var(--color-white-05);
    }
}

.content {
    background-color: var(--color-white);
}

.noResultWrapper {
    background-color: var(--color-white);
    padding: 10px 20px;
}

.noResultText {
    font-weight: bold;
}

.noResultIcon {
    margin-left: 30px;
}

.title {
    display: block;
    font-weight: bold;
    padding: 10px 0;
}

.airportSelectedTitle {
    font-size: $font-size-default;
    padding: 20px 20px 10px;
}

.loadingSpinner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}
