@import '@/assets/css/index.scss';

.shareLink {
    display: flex;
    padding: $content-padding--large $content-padding--medium;
    align-items: center;
    font-weight: bold;
    color: var(--color-endeavour);
}

.icon {
    margin-right: $content-padding--medium;
}
