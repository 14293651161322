@import '@/assets/css/index.scss';

@mixin item-styling {
    @include flex-gap-horizontal($content-padding-desktop--small);
    padding: $content-padding-desktop--medium $content-padding-desktop--small;
    font-size: $font-size-default-desktop;
    align-items: center;
    cursor: pointer;
}

.dropdown {
    display: flex;
    position: relative;
    flex-direction: column;
    box-shadow: $boxShadow-large;
    background-color: var(--color-white);
    border-radius: 3px;
}

.button {
    padding: $content-padding-desktop--small;
    position: sticky;
    bottom: 0;
}

.selectedItem,
.highlightedItem {
    @include item-styling();
    background-color: var(--color-foam);
}

.unselectedItem {
    @include item-styling();
    transition: all 0.3s ease;

    &:hover {
        background-color: var(--color-foam);
    }
}

.selectedBox {
    width: 22px;
    height: 22px;
    padding: 3px;
    background-color: var(--color-endeavour);
    border: 1px solid var(--color-endeavour);
    border-radius: 3px;
}

.unselectedBox {
    width: 12px;
    height: 12px;
    background-color: var(--color-white);
    border: 1px solid var(--color-nobel);
    border-radius: 3px;
    padding: $content-padding-desktop--small;
}

.noResultsText {
    padding: $content-padding-desktop--small;
}

.icon {
    pointer-events: none;
}
