@import '@/assets/css/index.scss';

.highlightedBadges {
    left: 4px;
    top: 15px;
    transform: translateX(-10px);
    height: 25px;
    border-radius: 3px 3px 3px 0;

    &::before {
        position: absolute;
        left: 0;
        bottom: -8px;
        content: '';
        width: 0;
        height: 0;
        line-height: 0;
        font-size: 0;
        border-right: 6px solid var(--color-japaneseLaurel);
        border-bottom: 8px solid transparent;
    }
}
