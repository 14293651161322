@import '@/assets/css/index.scss';

.title {
    font-weight: bold;
    margin-bottom: $content-padding--medium;
}

.items {
    align-items: stretch;
}
